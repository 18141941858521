*:focus {
  box-shadow: none !important;
}

.css-ugpl4b {
  background-color: #6666ff !important;
}

.fc-header-toolbar {
  margin-bottom: 10px !important;
}

.fc-button-primary {
  color: #ccc !important;
  background: none !important;
  border: 1px solid #ccc !important;
  border-radius: 50% !important;
  margin: 0 2rem !important;
  font-size: 10px !important;
  padding: 3px 4px !important;
}
.fc-today-button {
  border-radius: 20px !important;
  color: #999 !important;
  background: none !important;
  border: 1px solid #ccc !important;
  font-size: 12px !important;
  padding: 3px 10px !important;
  margin: 0 !important;
  margin-left: 10px !important;
}

.fc-toolbar-chunk > div {
  display: flex;
  align-items: center;
}

.fc-toolbar-title {
  color: #333 !important;
  font-size: 16px !important;
}

.fc-col-header-cell-cushion {
  font-size: 14px !important;
}

.fc-daygrid-day-number {
  font-size: 14px !important;
}

.fc-daygrid-dot-event {
  margin: 0 !important;
  padding: 0 !important;
}
.fc-daygrid-event-dot {
  margin: 2px !important;
}

.fc-daygrid-day-events {
  /* display: flex !important; */
  align-items: center;
  justify-content: center;
}
td.fc-daygrid-day.fc-day.fc-day-fri.fc-day-today {
  background-color: #fff;
}
td.fc-day-today a.fc-daygrid-day-number {
    background-color:#6667ff;
    color:#fff;
}
a.fc-daygrid-day-number {border-radius: 24px;}

.fc-event-title {
  font-size: 12px !important;
}

.fc-event-time {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .fc-view-harness {
    height: 410px !important;
  }
}

.css-1fkjgym[aria-checked="true"],
.css-1fkjgym[data-checked] {
  background-color: #6666ff !important;
}

.demo-editor {
  border: 1px solid #eee;
  padding: 0 1em;
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
}

.ql-editor {
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
}
